import * as React from 'react'
// @ts-ignore
import styled from 'styled-components'
import Layout from '../components/Layout/Layout'
import { Color } from '@/theme/colors'

// @ts-ignore
import Example1 from '@/assets/images/example1.jpg'
import { Bolder, Header1, Header2, Paragraph } from '@/theme/Typography.styled'
import { Container } from '@/theme/Grid.styled'
import Image1 from '@/assets/images/przygotowanie-do-wystaw.png'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${Color.Pink_1};
  text-align: center;

  img {
    width: 600px;
    max-width: 90%;
    margin: 30px auto;
  }
`

const Description = styled.div`
  padding: 50px 0;

  svg {
    margin: 50px 0;
  }

  @media (max-width: 991px) {
    padding: 0;

    svg {
      margin: 15px 0;
    }
  }
`

const Banner = styled.div`
  background-color: ${Color.Pink_2};
  padding: 50px 0;

  @media (max-width: 991px) {
    padding: 30px 0;
  }
`

const BannerInner = styled.div`
  display: grid;
  grid-row-gap: 15px;

  @media (max-width: 991px) {
    grid-row-gap: 0;
  }
`

const Pricing = styled.div`
  display: grid;
  grid-row-gap: 30px;
  justify-content: center;
  align-content: center;
  padding: 50px 0 70px;

  @media (max-width: 991px) {
    grid-row-gap: 15px;
    padding: 30px 0;
  }
`

const Head = () => (
  <>
    <title>Pet School - Przygotowanie do wystaw</title>
    <meta
      name="description"
      content="Spotkania indywidualne skierowane są dla osób które chcą rozpocząć swoją przygodę z wystawami psów."
    />
  </>
)

const DogShowPage = () => {
  return (
    <>
      <Head />
      <Layout>
        <Wrapper>
          <Container>
            <img alt="Pet School - przygotowanie do wystaw" src={Image1} />

            <Description>
              <Header2 marginBottom={39}>
                <Bolder>Przygotowanie do wystaw</Bolder>
              </Header2>

              <Paragraph>
                Spotkania indywidualne skierowane są dla osób które chcą
                rozpocząć swoją przygodę z wystawami psów.
              </Paragraph>
            </Description>
          </Container>

          <Banner>
            <Container>
              <BannerInner>
                <Header2>
                  <Bolder>Na zajęciach między innymi:</Bolder>
                </Header2>

                <Paragraph>- jak zapisać się na wystawy</Paragraph>
                <Paragraph>
                  - podstawowe pojęcia związanych z tytułami i wystawami
                </Paragraph>
                <Paragraph>- prezentowanie psa w statyce oraz ruchu</Paragraph>
                <Paragraph>- nauka pokazywania zębów psa</Paragraph>
                <Paragraph>- skupienie uwagi psa na przewodniku</Paragraph>
                <Paragraph>- odwrażliwanie psa na dotyk obcych osób</Paragraph>
                <Paragraph>- dobór i praca z ringówką</Paragraph>
                <Paragraph>- zapewnienie psu komfortu na wystawie</Paragraph>
              </BannerInner>
            </Container>
          </Banner>

          <Container>
            <Pricing>
              <div>
                <Paragraph>Handling indywidualny na placu:</Paragraph>
                <Header1>
                  <Bolder>150 zł / 60 minut</Bolder>
                </Header1>
              </div>

              <Paragraph>
                Częstotliwość:{` `}
                <Bolder>
                  Raz w tygodniu lub do ustalenia indywidualnie z klientem
                </Bolder>
              </Paragraph>
            </Pricing>
          </Container>
        </Wrapper>
      </Layout>
    </>
  )
}

export default DogShowPage
