import React from 'react'
import { Bolder, Paragraph } from '@/theme/Typography.styled'

const CostDriveInformation = () => (
  <Paragraph>
    Koszt dojazdu na konsultacje poza Wrocław liczony dodatkowo{` `}
    <Bolder>2 zł&nbsp;/&nbsp;km</Bolder> od Stabłowic.
  </Paragraph>
)

export default CostDriveInformation
