import React from 'react'
import * as Styled from './AboutMe.styled'
import { ButtonTextPink } from '@/theme/Button.styled'
import { Bolder } from '@/theme/Typography.styled'
import Image from '@/assets/images/about-me-1.png'

const AboutMe = () => {
  return (
    <Styled.Wrapper>
      <Styled.Container>
        <Styled.Image>
          <img
            alt="Pet school - szkolenia i terapia behawioralna"
            src={Image}
          />
        </Styled.Image>
        <Styled.Title>
          <Bolder>O mnie</Bolder>
        </Styled.Title>
        <Styled.Description>
          Jestem dyplomowanym behawiorystą zwierząt towarzyszących, ukończyłam
          kurs trenerski psów oraz dietetyki psów i kotów certyfikowane dyplomem
          MEN. Posiadam tytuł magistra biologii, w trakcie studiów zajmowałam
          się etologią zwierząt dzikich. Na co dzień pracuję jako technik
          weterynarii w jednej z wrocławskich klinik.
        </Styled.Description>

        <ButtonTextPink to="/o-mnie">Dowiedz się wiecej</ButtonTextPink>
      </Styled.Container>
    </Styled.Wrapper>
  )
}

export default AboutMe
