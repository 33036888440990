import React from 'react'
import ReactHtmlParser from 'react-html-parser'
import * as Styled from './ServicesCarouselItem.styled'
import { ButtonPink } from '@/theme/Button.styled'
import { ServiceInterface } from '@/interfaces/interfaces'

export const ServicesCarouselItem = ({
  description,
  icon,
  link,
  title,
}: ServiceInterface) => {
  const htmlTitle = ReactHtmlParser(title)
  return (
    <Styled.Wrapper>
      <Styled.Icon>
        <img alt={title} src={icon} />
      </Styled.Icon>
      <Styled.Title>{htmlTitle}</Styled.Title>
      <Styled.Description>{description}</Styled.Description>
      <ButtonPink to={link}>Sprawdź</ButtonPink>
    </Styled.Wrapper>
  )
}

export default ServicesCarouselItem
