import * as React from 'react'
// @ts-ignore
import styled from 'styled-components'
import Layout from '../components/Layout/Layout'
import { Color } from '@/theme/colors'
import IconCatCircle from '@/components/IconCatCircle/IconCatCircle'
// @ts-ignore
import Image1 from '@/assets/images/konsultacje-behawioralne-kotow.png'
import {
  Bolder,
  Header1,
  Header2,
  Header3,
  Paragraph,
} from '@/theme/Typography.styled'
import { Container } from '@/theme/Grid.styled'
import Banner1 from '@/components/Banner1/Banner1'
import DownloadFormBanner from '@/components/DownloadFormBanner/DownloadFormBanner'
import Table from "@/components/Table/Table";
import CostDriveInformation from "@/components/CostDriveInformation/CostDriveInformation";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${Color.Pink_1};
  text-align: center;

  img {
    width: 600px;
    max-width: 90%;
    margin: 30px auto;
  }

  @media (max-width: 991px) {
    svg {
      margin-bottom: 15px;
    }
  }
`

const Description = styled.div`
  padding: 50px 0;

  svg {
    margin: 30px 0;
  }

  @media (max-width: 991px) {
    padding: 0;

    svg {
      margin: 15px 0;
    }
  }
`

const Head = () => (
  <>
    <title>Pet School - Konsultacje behawioralne kotów</title>
    <meta
      name="description"
      content="Na konsultacji indywidualnej, która odbywa się u klienta, analizuję dokładnie problem, zbieram wywiad oraz przyglądam się komunikacji i relacji pomiędzy kotem a domownikami."
    />
  </>
)

const CatBehavioralConsultationsPage = () => {
  return (
    <>
      <Head />
      <Layout>
        <Wrapper>
          <Container>
            <img
              alt="Pet School - konsultacje behawioralne kotów"
              src={Image1}
            />
            <Description>
              <Header2 marginBottom={39}>
                <Bolder>Konsultacja behawioralna kotów</Bolder>
              </Header2>

              <Paragraph>
                Na konsultacji indywidualnej, która odbywa się u klienta,
                analizuję dokładnie problem, zbieram wywiad oraz przyglądam się
                komunikacji i relacji pomiędzy kotem a domownikami. Staram się
                dobierać metody pracy, które będą w danym przypadku
                najskuteczniejsze.
              </Paragraph>

              <Paragraph>
                Zajmuje się między innymi takimi problemami jak: nadmierna
                lękliwość i fobie, nadpobudliwość, agresja do ludzi i zwierząt.
                Również pomagam wprowadzić kota do psa oraz drugiego kota do
                domu.
              </Paragraph>

              <IconCatCircle />

              <Paragraph>
                Oferuję doradztwo w wyborze rasy, hodowli oraz adopcji oraz
                pierwszych dni w nowym domu zarówno kociaka jak i kotów
                dorosłych.
              </Paragraph>

              <Paragraph>
                Podczas konsultacji dotyczących wychowania kociaka opowiadam
                między innymi o etapach rozwoju kota, specyfice rasy, kocich
                potrzebach i jak prawidłowo je zaspokajać, jak przeprowadzić
                prawidłową socjalizację i habituację, o komunikacji kotów oraz
                najważniejsze akcesoria w domu.
              </Paragraph>
            </Description>
          </Container>

          <Banner1>
            <Header3>
              <Bolder>
                Zawsze po konsultacji, wysyłam klientowi zalecenia wraz z planem
                pracy i materiałami z zakresu komunikacji kotów.
              </Bolder>
            </Header3>
          </Banner1>

          <Container>
            <Description>
              <Header2 marginBottom={40}>
                <Bolder>Cennik</Bolder>
              </Header2>

              <Table>
                <tr>
                  <td>
                    <Paragraph>Pierwsza konsultacja</Paragraph>
                  </td>
                  <td>
                    <Paragraph>250&nbsp;zł</Paragraph>
                  </td>
                  <td>
                    <Paragraph>1,5&nbsp;h</Paragraph>
                  </td>
                </tr>

                <tr>
                  <td>
                    <Paragraph>Kolejna konsultacja</Paragraph>
                  </td>
                  <td>
                    <Paragraph>150&nbsp;zł</Paragraph>
                  </td>
                  <td>
                    <Paragraph>1&nbsp;h</Paragraph>
                  </td>
                </tr>

                <tr>
                  <td>
                    <Paragraph>Pierwsza konsultacja ONLINE</Paragraph>
                  </td>
                  <td>
                    <Paragraph>200&nbsp;zł</Paragraph>
                  </td>
                  <td>
                    <Paragraph>1,5&nbsp;h</Paragraph>
                  </td>
                </tr>

                <tr>
                  <td>
                    <Paragraph>Kolejna konsultacja ONLINE</Paragraph>
                  </td>
                  <td>
                    <Paragraph>100&nbsp;zł</Paragraph>
                  </td>
                  <td>
                    <Paragraph>1&nbsp;h</Paragraph>
                  </td>
                </tr>

                <tr>
                  <td>
                    <Paragraph>
                      Każde kolejne rozpoczęte 30 minut konsultacji
                    </Paragraph>
                  </td>
                  <td>
                    <Paragraph>30&nbsp;zł</Paragraph>
                  </td>
                  <td></td>
                </tr>
              </Table>
            </Description>
            <Description>
              <CostDriveInformation />
            </Description>
          </Container>

          <DownloadFormBanner cat={true} />
        </Wrapper>
      </Layout>
    </>
  )
}

export default CatBehavioralConsultationsPage
