// @ts-ignore
import styled from 'styled-components'
import { Color } from '@/theme/colors'

export const Table = styled.table`
  margin: 0 auto;
  width: 80%;
  text-align: left;
  border-spacing: 0;

  tr {
    td {
      padding: 16px;

      p {
        margin: 0;
      }
    }

    &:nth-child(odd) {
      td {
        background-color: ${Color.Pink_2};
      }
    }
  }

  @media (max-width: 991px) {
    width: 100%;
  }
`
