import * as React from 'react'
// @ts-ignore
import styled from 'styled-components'
import Layout from '../components/Layout/Layout'
import { Color } from '@/theme/colors'
// @ts-ignore
import Image1 from '@/assets/images/szkolenia-indywidualne-1.png'
import { Bolder, Header1, Header2, Paragraph } from '@/theme/Typography.styled'
import { Container } from '@/theme/Grid.styled'
import IconFootPrintDog from '@/components/IconFootPrintDog/IconFootPrintDog'
import Table from '@/components/Table/Table'
import CostDriveInformation from '@/components/CostDriveInformation/CostDriveInformation'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${Color.Pink_1};
  text-align: center;
`

const Top = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-column-gap: 50px;
  align-items: center;
  text-align: center;
  padding: 30px 0 60px;

  .bean {
    position: relative;

    img {
      width: 683px;
      max-width: 100%;
    }
  }

  .foot-print {
    position: absolute;
    bottom: 80px;
    right: 80px;
  }

  @media (max-width: 1199px) {
    grid-template-columns: 480px 1fr;
  }

  @media (max-width: 991px) {
    display: block;
    padding: 30px 0;

    .bean {
      text-align: center;
      margin-bottom: 30px;
    }

    .foot-print {
      position: absolute;
      bottom: 40px;
      right: 40px;

      svg {
        width: 55px;
        height: 55px;
      }
    }
  }
`

const Description = styled.div`
  background-color: ${Color.Pink_2};
  padding: 50px 0;

  @media (max-width: 991px) {
    padding: 30px 0;
  }
`

const DescriptionInner = styled.div`
  display: grid;
  grid-row-gap: 15px;

  @media (max-width: 991px) {
    grid-row-gap: 0;
  }
`

const Pricing = styled.div`
  ${Description} {
    background-color: ${Color.Pink_1};
  }
`

const Head = () => (
  <>
    <title>Pet School - Szkolenia indywidualne psów</title>
    <meta
      name="description"
      content="Zajęcia odbywają się w Parku Zachodnim, Stabłowickim lub na ogrodzonym placu przy ulicy Mącznej. Głównie kierowane są do psów w każdym wieku, które mają problem ze skupieniem uwagi podczas pracy grupie, są lękliwe lub mocno pobudliwe."
    />
  </>
)

const DogBehavioralConsultationsPage = () => {
  return (
    <>
      <Head />
      <Layout>
        <Wrapper>
          <Container>
            <Top>
              <div className="bean">
                <img
                  alt="Pet School - szkolenia indywidualne psów"
                  src={Image1}
                />
                <div className="foot-print">
                  <IconFootPrintDog />
                </div>
              </div>
              <div>
                <Header2>
                  <Bolder>Szkolenia indywidualne psów</Bolder>
                </Header2>

                <Paragraph>
                  W trakcie indywidualnego szkolenia, dokładnie skupiam się na
                  potrzebach i problemach Twojego psa. Indywidualnie dobieram
                  metodykę pracy zależnie od jego temperamentu
                  i&nbsp;predyspozycji. Zajęcia odbywają się w Parku Zachodnim,
                  Stabłowickim lub na ogrodzonym placu przy ulicy Mącznej.
                  Głównie kierowane są do psów w każdym wieku, które mają
                  problem ze skupieniem uwagi podczas pracy grupie,
                  są&nbsp;lękliwe lub mocno pobudliwe.
                </Paragraph>
              </div>
            </Top>
          </Container>

          <Description>
            <Container>
              <DescriptionInner>
                <Header2>
                  <Bolder>Zajęcia obejmują</Bolder>
                </Header2>

                <Paragraph>
                  - naukę budowania odpowiedniej motywacji psa oraz współpracy
                  przewodnika z psem
                </Paragraph>
                <Paragraph>
                  - podstawowych komend między innymi: równaj, siad, waruj,
                  stój, zostań, chodzenie na luźnej smyczy, skupienie uwagi,
                  zostaw, do mnie
                </Paragraph>
                <Paragraph>
                  - spokojnego mijania się innymi psami lub różnymi bodźcami, z
                  którymi ma problem Twój pies
                </Paragraph>
                <Paragraph>
                  - odczytywania sygnałów wysyłanych przez Twojego psa
                </Paragraph>
                <Paragraph>
                  - rozumienia emocji Twojego psa oraz radzenia sobie z nimi
                </Paragraph>
              </DescriptionInner>
            </Container>
          </Description>

          <Pricing>
            <Container>
              <Description>
                <Header2 marginBottom={40}>
                  <Bolder>Cennik</Bolder>
                </Header2>

                <Table>
                  <tr>
                    <td>
                      <Paragraph>Szkolenie indywidualne na placu</Paragraph>
                    </td>
                    <td>
                      <Paragraph>150&nbsp;zł</Paragraph>
                    </td>
                    <td>
                      <Paragraph>1&nbsp;h</Paragraph>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <Paragraph>Szkolenie indywidualne w parku</Paragraph>
                    </td>
                    <td>
                      <Paragraph>100&nbsp;zł</Paragraph>
                    </td>
                    <td>
                      <Paragraph>1&nbsp;h</Paragraph>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <Paragraph>Trening medyczny na placu</Paragraph>
                    </td>
                    <td>
                      <Paragraph>150&nbsp;zł</Paragraph>
                    </td>
                    <td>
                      <Paragraph>1&nbsp;h</Paragraph>
                    </td>
                  </tr>
                </Table>
              </Description>
              <Description>
                <CostDriveInformation />
              </Description>
            </Container>
          </Pricing>
        </Wrapper>
      </Layout>
    </>
  )
}

export default DogBehavioralConsultationsPage
