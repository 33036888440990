// @ts-ignore
import styled from 'styled-components'
import { Container as _Container } from '@/theme/Grid.styled'
import { Header2, Paragraph } from '@/theme/Typography.styled'
import { Color } from '@/theme/colors'

export const Wrapper = styled.div`
  padding: 80px 0;
  background-color: ${Color.Pink_1};

  @media (max-width: 991px) {
    padding: 30px 0 60px;
  }
`

export const Container = styled(_Container)`
  display: grid;
  grid-auto-flow: row;
  justify-items: center;
  grid-row-gap: 30px;
  text-align: center;

  @media (max-width: 991px) {
    grid-row-gap: 15px;
  }

  @media (max-width: 767px) {
    grid-row-gap: 0;
  }
`

export const Image = styled.div`
  img {
    width: 757px;
    max-width: 100%;
    height: auto;
  }

  @media (max-width: 991px) {
    width: 100%;
    max-width: 757px;
  }
`

export const Title = styled(Header2)``

export const Description = styled(Paragraph)`
  max-width: 900px;
  width: 100%;
`
