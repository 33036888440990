import React from 'react'
import * as Styled from './BannerHome.styled'
import { Bolder } from '@/theme/Typography.styled'

import Image1 from '@/assets/images/pet-school-banner-1.png'

const BannerHome = () => {
  return (
    <Styled.Wrapper>
      <Styled.Container>
        <Styled.Image>
          <img alt="Pet school" src={Image1} />
        </Styled.Image>
        <Styled.Text>
          <Styled.Header>
            <Bolder>
              Dietetyka,
              <br />
              szkolenia i terapia
            </Bolder>
            <br />
            behawioralna zwierząt
          </Styled.Header>
          <Styled.Description>
            Jestem dyplomowanym behawiorystą zwierząt, trenerem psów oraz
            dietetykiem psów i kotów. Oferuję terapie behawioralne psów
            i&nbsp;kotów, szkolenia psów metodą pozytywnych wzmocnień oraz dobór
            odpowiedniej diety.
          </Styled.Description>
          <Styled.Button to="#oferta">Sprawdź jak mogę Ci pomóc</Styled.Button>
        </Styled.Text>
      </Styled.Container>
    </Styled.Wrapper>
  )
}

export default BannerHome
