import React from 'react'
import { Bolder, Header2, Paragraph } from '@/theme/Typography.styled'
import { ButtonTextPink } from '@/theme/Button.styled'
import Banner1 from '@/components/Banner1/Banner1'

interface Props {
  cat?: boolean
  diet?: boolean
  dog?: boolean
}
const DownloadFormBanner = ({ cat, diet, dog }: Props) => {
  const text = (() => {
    if ((cat && diet) || (dog && diet)) {
      return `behawioralną lub dietetyczną`
    }

    if (cat || dog) {
      return `behawioralną`
    }

    return `dietetyczną`
  })()

  return (
    <Banner1>
      <Header2>
        <Bolder>Pamiętaj o ankiecie</Bolder>
      </Header2>
      <Paragraph>
        Wypełnij ankietę {text} przed rozpoczęciem konsultacji:
      </Paragraph>
      {dog && (
        <ButtonTextPink
          download
          to="https://petschool.pl/download/ankieta-behawioralna-pies.docx"
        >
          Pobierz ankietę behawioralną - PIES
        </ButtonTextPink>
      )}
      {cat && dog && <br />}
      {cat && (
        <ButtonTextPink
          download
          to="https://petschool.pl/download/ankieta-behawioralna-kot.docx"
        >
          Pobierz ankietę behawioralną - KOT
        </ButtonTextPink>
      )}
      {((cat && diet) || (dog && diet)) && <br />}
      {diet && (
        <ButtonTextPink
          download
          to="https://petschool.pl/download/ankieta-dietetyczna.docx"
        >
          Pobierz ankietę dietetyczną
        </ButtonTextPink>
      )}
    </Banner1>
  )
}

export default DownloadFormBanner
