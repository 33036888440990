// @ts-ignore
import styled from 'styled-components'
import { Color } from '@/theme/colors'

export const Description = styled.div`
  background-color: ${Color.Pink_2};
  padding: 50px 0;
  text-align: center;

  @media (max-width: 991px) {
    padding: 30px 0;
  }
`
