import * as React from 'react'
// @ts-ignore
import styled from 'styled-components'
import Layout from '../components/Layout/Layout'
import { Color } from '@/theme/colors'
// @ts-ignore
import Image1 from '@/assets/images/konsultacje-dietetyczne-psow-i-kotow.png'
import { Bolder, Header1, Header2, Paragraph } from '@/theme/Typography.styled'
import { Container } from '@/theme/Grid.styled'
import IconFootPrintDog from '@/components/IconFootPrintDog/IconFootPrintDog'
import Table from '@/components/Table/Table'
import DownloadFormBanner from '@/components/DownloadFormBanner/DownloadFormBanner'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${Color.Pink_1};
  text-align: center;
`

const Top = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-column-gap: 50px;
  align-items: center;
  text-align: center;
  padding: 30px 0 60px;

  .bean {
    position: relative;

    img {
      width: 683px;
      max-width: 100%;
    }
  }

  @media (max-width: 1199px) {
    grid-template-columns: 480px 1fr;
  }

  @media (max-width: 991px) {
    display: block;
    padding: 30px 0;

    .bean {
      text-align: center;
      margin-bottom: 30px;
    }
  }
`

const Description = styled.div`
  background-color: ${Color.Pink_2};
  padding: 50px 0;

  @media (max-width: 991px) {
    padding: 30px 0;
  }
`

const Description2 = styled(Description)`
  background-color: ${Color.Pink_1};
`

const DescriptionInner = styled.div`
  display: grid;
  grid-row-gap: 15px;

  @media (max-width: 991px) {
    grid-row-gap: 0;
  }
`

const Pricing = styled.div`
  ${Description} {
    background-color: ${Color.Pink_1};
  }
`

const Head = () => (
  <>
    <title>Pet School - Konsultacje dietetyczne psów i kotów</title>
    <meta
      name="description"
      content="Konsultacja dietetyczna obejmuje dobór odpowiedniej diety zarówno dla zdrowych zwierząt jak i
tych zmagających się różnymi schorzeniami."
    />
  </>
)

const DieteticsPage = () => {
  return (
    <>
      <Head />
      <Layout>
        <Wrapper>
          <Container>
            <Top>
              <div className="bean">
                <img
                  alt="Pet School - konsultacje dietetyczne psów i kotów"
                  src={Image1}
                />
              </div>
              <div>
                <Header2>
                  <Bolder>
                    Konsultacje dietetyczne
                    <br />
                    psów i kotów
                  </Bolder>
                </Header2>

                <Paragraph>
                  Konsultacja dietetyczna odbywa się w formie online za
                  pośrednictwem kontaktu mailowego. Obejmuje dobór odpowiedniej
                  diety zarówno dla zdrowych zwierząt jak i tych zmagających się
                  różnymi schorzeniami.
                </Paragraph>
              </div>
            </Top>
          </Container>

          <Description>
            <Container>
              <DescriptionInner>
                <Header2>
                  <Bolder>Dla kogo są konsultacje?</Bolder>
                </Header2>

                <Paragraph>
                  Konsultacje dietetyczne są dedykowane zarówno zdrowym
                  zwierzętom, jak i tym zmagającym się z różnymi schorzeniami, w
                  tym:
                </Paragraph>

                <Paragraph>- otyłością, niedowagą,</Paragraph>
                <Paragraph>
                  - chorobami układu pokarmowego i moczowego,
                </Paragraph>
                <Paragraph>- alergiami pokarmowymi,</Paragraph>
                <Paragraph>- chorobami onkologicznymi.</Paragraph>

                <Paragraph>Są także przeznaczone dla:</Paragraph>

                <Paragraph>- rosnących psów i kotów,</Paragraph>
                <Paragraph>- psów sportowych i pracujących,</Paragraph>
                <Paragraph>- zwierząt starszych.</Paragraph>
              </DescriptionInner>
            </Container>
          </Description>

          <Description2>
            <Container>
              <DescriptionInner>
                <Header2>
                  <Bolder>Co obejmują konsultacje?</Bolder>
                </Header2>

                <Paragraph>
                  - Indywidualny dobór diety: gotowanej, surowej (BARF) lub
                  karmy komercyjnej.
                </Paragraph>

                <Paragraph>
                  - Dostosowanie suplementacji do potrzeb i stanu zdrowia
                  zwierzęcia.
                </Paragraph>
                <Paragraph>- Analizę wyników badań i ocenę sylwetki.</Paragraph>
                <Paragraph>
                  W ciągu tygodnia od dostarczenia wymaganych dokumentów, klient
                  otrzymuje zalecenia dietetyczne, a w przypadku diety gotowanej
                  lub surowej – gotowy przepis. W cenie konsultacji przewidziane
                  są dwa tygodnie na dodatkowe pytania i ewentualne modyfikacje
                  zaleceń.
                </Paragraph>
              </DescriptionInner>
            </Container>
          </Description2>

          <Description>
            <Container>
              <DescriptionInner>
                <Header2>
                  <Bolder>Jakie informacje są wymagane do konsultacji?</Bolder>
                </Header2>

                <Table>
                  <tr>
                    <td>
                      <Paragraph>
                        Aby rozpocząć proces konsultacji, prosimy o
                        dostarczenie:
                      </Paragraph>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <Paragraph>- Wypełnionej ankiety dietetycznej.</Paragraph>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <Paragraph>
                        - Dwóch zdjęć sylwetki zwierzęcia w pozycji stojącej (z
                        boku i od góry).
                      </Paragraph>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <Paragraph>- Aktualnych badań:</Paragraph>
                      <ul>
                        <li style={{ marginBottom: `8px` }}>
                          <Paragraph>
                            morfologia i biochemia (w tym: albuminy, ALT,
                            amylaza, AST, białko całkowite, bilirubina,
                            cholesterol, kreatynina, lipaza, mocznik,
                            trójglicerydy, jonogram itp.),
                          </Paragraph>
                        </li>

                        <li>
                          <Paragraph>
                            u seniorów dodatkowo panel tarczycowy.
                          </Paragraph>
                        </li>
                      </ul>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <Paragraph>
                        - W przypadku zwierząt chorych: historię leczenia oraz
                        wyniki badań.
                      </Paragraph>
                    </td>
                  </tr>
                </Table>
              </DescriptionInner>
            </Container>
          </Description>

          <Pricing>
            <Container>
              <Description>
                <Header2 marginBottom={40}>
                  <Bolder>Cennik</Bolder>
                </Header2>

                <Table>
                  <tr>
                    <td>
                      <Paragraph>Pierwsza konsultacja dietetyczna</Paragraph>
                    </td>
                    <td>
                      <Paragraph>200&nbsp;zł</Paragraph>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <Paragraph>Kolejna konsultacja dietetyczna</Paragraph>
                    </td>
                    <td>
                      <Paragraph>150&nbsp;zł</Paragraph>
                    </td>
                  </tr>
                </Table>

                <Paragraph>
                  * W cenie konsultacji przewidziane są dwa tygodnie na
                  dodatkowe pytania i ewentualne modyfikacje zaleceń.
                </Paragraph>
              </Description>
            </Container>
          </Pricing>

          <DownloadFormBanner diet={true} />
        </Wrapper>
      </Layout>
    </>
  )
}

export default DieteticsPage
