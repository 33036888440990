import React from 'react'
import * as Styled from './Banner1.styled'

const Banner1 = ({ children }) => (
  <Styled.Wrapper>
    <Styled.Overlay>
      <Styled.Container>{children}</Styled.Container>
    </Styled.Overlay>
  </Styled.Wrapper>
)

export default Banner1
