exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-galeria-tsx": () => import("./../../../src/pages/galeria.tsx" /* webpackChunkName: "component---src-pages-galeria-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-konsultacje-behawioralne-kotow-tsx": () => import("./../../../src/pages/konsultacje-behawioralne-kotow.tsx" /* webpackChunkName: "component---src-pages-konsultacje-behawioralne-kotow-tsx" */),
  "component---src-pages-konsultacje-behawioralne-psow-tsx": () => import("./../../../src/pages/konsultacje-behawioralne-psow.tsx" /* webpackChunkName: "component---src-pages-konsultacje-behawioralne-psow-tsx" */),
  "component---src-pages-konsultacje-dietetyczne-psow-i-kotow-tsx": () => import("./../../../src/pages/konsultacje-dietetyczne-psow-i-kotow.tsx" /* webpackChunkName: "component---src-pages-konsultacje-dietetyczne-psow-i-kotow-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-o-mnie-tsx": () => import("./../../../src/pages/o-mnie.tsx" /* webpackChunkName: "component---src-pages-o-mnie-tsx" */),
  "component---src-pages-przygotowanie-do-wystaw-tsx": () => import("./../../../src/pages/przygotowanie-do-wystaw.tsx" /* webpackChunkName: "component---src-pages-przygotowanie-do-wystaw-tsx" */),
  "component---src-pages-psie-przedszkole-tsx": () => import("./../../../src/pages/psie-przedszkole.tsx" /* webpackChunkName: "component---src-pages-psie-przedszkole-tsx" */),
  "component---src-pages-spacery-socjalizacyjne-tsx": () => import("./../../../src/pages/spacery-socjalizacyjne.tsx" /* webpackChunkName: "component---src-pages-spacery-socjalizacyjne-tsx" */),
  "component---src-pages-szkolenia-indywidualne-psow-tsx": () => import("./../../../src/pages/szkolenia-indywidualne-psow.tsx" /* webpackChunkName: "component---src-pages-szkolenia-indywidualne-psow-tsx" */)
}

