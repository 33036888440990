import React from 'react'
import * as Styled from './Table.styled'

interface TableProps {
  children: React.ReactNode
}

const Table = ({ children }: TableProps) => (
  <Styled.Table>{children}</Styled.Table>
)

export default Table
