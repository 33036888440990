import * as React from 'react'
// @ts-ignore
import styled from 'styled-components'
import Layout from '../components/Layout/Layout'
import { Color } from '@/theme/colors'

// @ts-ignore
import Example1 from '@/assets/images/example1.jpg'
import {
  Bolder,
  Header2,
  Header3,
  Paragraph,
} from '@/theme/Typography.styled'
import { Container } from '@/theme/Grid.styled'
import Banner1 from '@/components/Banner1/Banner1'
import Image1 from '@/assets/images/psie-przedszkole.png'
import Table from '@/components/Table/Table'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${Color.Pink_1};
  text-align: center;

  img {
    width: 600px;
    max-width: 90%;
    margin: 30px auto;
  }
`

const Description = styled.div`
  padding: 50px 0;

  img {
    margin: 50px 0;
  }

  @media (max-width: 991px) {
    padding: 0;

    img {
      margin: 15px 0;
    }
  }
`

const Banner = styled.div`
  background-color: ${Color.Pink_2};
  padding: 50px 0;

  @media (max-width: 991px) {
    padding: 30px 0;
  }
`

const BannerInner = styled.div`
  display: grid;
  grid-row-gap: 15px;

  @media (max-width: 991px) {
    grid-row-gap: 0;
  }
`

const Head = () => (
  <>
    <title>Pet School - Psie przedszkole</title>
    <meta
      name="description"
      content="Spotkania odbywają się w grupie od 2 do 4 szczeniąt lub w trybie indywidualnym na ogrodzonym placu treningowym przy ulicy Mącznej. Zawierają 5 zajęć praktycznych na placu oraz 1 spacer socjalizacyjny na wałach na Praczach Odrzańskich."
    />
  </>
)

const DogBehavioralConsultationsPage = () => {
  return (
    <>
      <Head />
      <Layout>
        <Wrapper>
          <Container>
            <img alt="Pet School - psie przedszkole" src={Image1} />

            <Description>
              <Header2 marginBottom={39}>
                <Bolder>Psie przedszkole</Bolder>
              </Header2>

              <Paragraph>
                Zajęcia skierowane są dla szczeniąt w wieku od 2* do 5 miesięcy
                (*po drugiej dawce szczepień na choroby zakaźne).
              </Paragraph>

              <Paragraph>
                Spotkania odbywają się w grupie od 2 do 4 szczeniąt lub w trybie
                indywidualnym na ogrodzonym placu treningowym przy ulicy
                Mącznej. Zawierają 5 zajęć praktycznych na placu oraz 1 spacer
                socjalizacyjny na wałach na Praczach Odrzańskich.
              </Paragraph>
            </Description>
          </Container>

          <Banner>
            <Container>
              <BannerInner>
                <Header2>
                  <Bolder> Na kursie między innymi:</Bolder>
                </Header2>

                <Paragraph>- metody motywacji u psa</Paragraph>
                <Paragraph>- nauka prawidłowej zabawy i odpoczynku</Paragraph>
                <Paragraph>- podstawy psiej komunikacji</Paragraph>
                <Paragraph>- habituacja i socjalizacja</Paragraph>
                <Paragraph>- nauka ignorowania psów i ludzi</Paragraph>
                <Paragraph>- jak prawidłowo dotykać psa</Paragraph>
                <Paragraph>
                  - jak prawidłowo przeprowadzić trening medyczny
                </Paragraph>
                <Paragraph>- nauka chodzenia na luźnej smyczy</Paragraph>
                <Paragraph>
                  - podstawowe komendy takie jak: siad, leżeć, do mnie, komenda
                  zwalniającą, skupienie uwagi na przewodniku, odsyłanie na
                  miejsce, różne sztuczki, podążanie za przewodnikiem
                </Paragraph>
              </BannerInner>
            </Container>
          </Banner>

          <Banner1>
            <Header3>
              <Bolder>
                Po każdych zajęciach uczestnicy otrzymują materiały teoretyczne.
              </Bolder>
            </Header3>
          </Banner1>

          <Container>
            <Description>
              <Header2 marginBottom={40}>
                <Bolder>Cennik</Bolder>
              </Header2>

              <Table>
                <tr>
                  <td>
                    <Paragraph>Psie przedszkole zajęcia indywidualne</Paragraph>
                  </td>
                  <td>
                    <Paragraph>150&nbsp;zł</Paragraph>
                  </td>
                  <td>
                    <Paragraph>1&nbsp;h</Paragraph>
                  </td>
                </tr>

                <tr>
                  <td>
                    <Paragraph>Psie przedszkole zajęcia grupowe</Paragraph>
                  </td>
                  <td>
                    <Paragraph>550&nbsp;zł</Paragraph>
                  </td>
                  <td>
                    <Paragraph>6 spotkań x 1&nbsp;h</Paragraph>
                  </td>
                </tr>
              </Table>
            </Description>
          </Container>
        </Wrapper>
      </Layout>
    </>
  )
}

export default DogBehavioralConsultationsPage
