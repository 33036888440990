// @ts-ignore
import Icon1 from '@/assets/images/icon_1.svg'
// @ts-ignore
import Icon2 from '@/assets/images/icon_2.svg'
// @ts-ignore
import Icon3 from '@/assets/images/icon_3.svg'
// @ts-ignore
import Icon4 from '@/assets/images/psie-przedszkole-icon.png'
// @ts-ignore
import Icon5 from '@/assets/images/spacery-socjalizacyjne-icon.png'
// @ts-ignore
import Icon6 from '@/assets/images/przygotowanie-do-wystaw-icon.png'
// @ts-ignore
import Icon7 from '@/assets/images/icon_7.png'

export const Settings = {
  arrows: false,
  autoplay: true,
  dots: true,
  infinite: true,
  pauseOnHover: true,
  speed: 1200,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
}

export const Services = [
  {
    description: `Konsultacja dietetyczna obejmuje dobór odpowiedniej diety zarówno dla zdrowych zwierząt jak i
tych zmagających się różnymi schorzeniami.`,
    icon: Icon7,
    link: `/konsultacje-dietetyczne-psow-i-kotow`,
    title: `Konsultacje dietetyczne<br/>psów i kotów`,
  },
  {
    description: `W trakcie indywidualnego szkolenia, dokładnie skupiam się na potrzebach i problemach Twojego psa. Oferuję zajęcia dla psów w każdym wieku.`,
    icon: Icon1,
    link: `/szkolenia-indywidualne-psow`,
    title: `Szkolenia<br/>indywidualne psów`,
  },
  {
    description: `Terapia zaburzeń zachowań, przygotowanie psa na pojawienie się nowego członka rodziny oraz doradztwo w zakresie adopcji, wyboru rasy i hodowli. `,
    icon: Icon2,
    link: `/konsultacje-behawioralne-psow`,
    title: `Konsultacje<br/>behawioralne psów`,
  },
  {
    description: `Konsultacja behawioralna kota obejmuje terapię zaburzeń zachowania oraz pomoc w wyborze rasy, adopcji, hodowli i pierwszych dni w nowym domu.`,
    icon: Icon3,
    link: `/konsultacje-behawioralne-kotow`,
    title: `Konsultacje<br/>behawioralne kotów`,
  },
  {
    description: `Zajęcia grupowe dla szczeniąt w wieku od 2 do 5 miesięcy. Spotkania odbywają się na placu treningowym w małych grupach od 2 do 4 psów.`,
    icon: Icon4,
    link: `/psie-przedszkole`,
    title: `Psie<br/>przedszkole`,
  },
  {
    description: `Nauka psa przebywania z innymi psami, opanowanie emocji, wyciszenie, okazja do komunikowania się psów w spokojnych warunkach. `,
    icon: Icon5,
    link: `/spacery-socjalizacyjne`,
    title: `Spacery<br/>socjalizacyjne`,
  },
  {
    description: `Nauka prezentacji psa w statyce oraz ruchu, pokazywania zębów, skupienia uwagi psa na przewodniku oraz odwrażliwanie na dotyk obcych osób.`,
    icon: Icon6,
    link: `/przygotowanie-do-wystaw`,
    title: `Przygotowanie<br/>do wystaw`,
  },
]
