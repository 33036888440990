import * as React from 'react'
// @ts-ignore
import styled from 'styled-components'
import Layout from '../components/Layout/Layout'
import { Color } from '@/theme/colors'

// @ts-ignore
import {
  Bolder,
  Header1,
  Header2,
  Header3,
  Paragraph,
} from '@/theme/Typography.styled'
import { Container } from '@/theme/Grid.styled'
import Banner1 from '@/components/Banner1/Banner1'
import DownloadFormBanner from '@/components/DownloadFormBanner/DownloadFormBanner'
import Image1 from '@/assets/images/konsultacje-behawioralne-1.png'
import Image2 from '@/assets/images/konsultacje-behawioralne-2.png'
import Table from '@/components/Table/Table'
import CostDriveInformation from '@/components/CostDriveInformation/CostDriveInformation'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${Color.Pink_1};
  text-align: center;

  img {
    width: 600px;
    max-width: 90%;
    margin: 30px auto;
  }
`

const Description = styled.div`
  padding: 50px 0;

  img {
    margin: 50px 0;
  }

  @media (max-width: 991px) {
    padding: 0;

    img {
      margin: 15px 0;
    }
  }
`

const Head = () => (
  <>
    <title>Pet School - Konsultacje behawioralne psów</title>
    <meta
      name="description"
      content="Na konsultacji indywidualnej, która odbywa się u klienta, analizuję dokładnie problem, zbieram dokładny wywiad oraz przyglądam się komunikacji i relacji pomiędzy psem a przewodnikiem."
    />
  </>
)

const DogBehavioralConsultationsPage = () => {
  return (
    <>
      <Head />
      <Layout>
        <Wrapper>
          <Container>
            <img
              alt="Pet School - konsultacje behawioralne psów"
              src={Image1}
            />

            <Description>
              <Header2 marginBottom={39}>
                <Bolder>Konsultacje behawioralne psów</Bolder>
              </Header2>

              <Paragraph>
                Na konsultacji indywidualnej, która odbywa się u klienta,
                analizuję dokładnie problem, zbieram dokładny wywiad oraz
                przyglądam się komunikacji i relacji pomiędzy psem a
                przewodnikiem. Staram się dobierać metody pracy, które będą w
                danym przypadku najskuteczniejsze.
              </Paragraph>

              <Paragraph>
                Zajmuje się między innymi takimi problemami jak: nadmierna
                lękliwość i fobie, zaburzenia separacyjne, nadpobudliwość,
                agresja do ludzi i zwierząt. Również pomagam wprowadzić psa do
                kota oraz drugiego psa do domu.
              </Paragraph>

              <img
                alt="Pet School - konsultacje behawioralne psów"
                src={Image2}
              />

              <Paragraph>
                Oferuję doradztwo w wyborze rasy, hodowli oraz adopcji oraz
                pierwszych dni w nowym domu zarówno szczeniaka jak i psów
                dorosłych.
              </Paragraph>

              <Paragraph>
                Podczas konsultacji dotyczących wychowania szczeniaczka
                opowiadam między innymi o etapach rozwoju psa, specyfice rasy,
                potrzebach psa i jak prawidłowo je zaspokajać, jak przeprowadzić
                prawidłową socjalizację i habituację, jak nauczyć psa
                odpoczynku, wprowadzenie nauki zostawania samemu w domu,
                treningu czystości, o komunikacji psów oraz najważniejsze
                akcesoria na spacery i do domu.
              </Paragraph>
            </Description>
          </Container>

          <Banner1>
            <Header3>
              <Bolder>
                Zawsze po konsultacji, wysyłam klientowi zalecenia wraz z planem
                pracy i materiałami z zakresu komunikacji psów.
              </Bolder>
            </Header3>
          </Banner1>

          <Container>
            <Description>
              <Header2 marginBottom={40}>
                <Bolder>Cennik</Bolder>
              </Header2>

              <Table>
                <tr>
                  <td>
                    <Paragraph>Pierwsza konsultacja</Paragraph>
                  </td>
                  <td>
                    <Paragraph>250&nbsp;zł</Paragraph>
                  </td>
                  <td>
                    <Paragraph>1,5&nbsp;h</Paragraph>
                  </td>
                </tr>

                <tr>
                  <td>
                    <Paragraph>Kolejna konsultacja</Paragraph>
                  </td>
                  <td>
                    <Paragraph>150&nbsp;zł</Paragraph>
                  </td>
                  <td>
                    <Paragraph>1&nbsp;h</Paragraph>
                  </td>
                </tr>

                <tr>
                  <td>
                    <Paragraph>Pierwsza konsultacja ONLINE</Paragraph>
                  </td>
                  <td>
                    <Paragraph>200&nbsp;zł</Paragraph>
                  </td>
                  <td>
                    <Paragraph>1,5&nbsp;h</Paragraph>
                  </td>
                </tr>

                <tr>
                  <td>
                    <Paragraph>Kolejna konsultacja ONLINE</Paragraph>
                  </td>
                  <td>
                    <Paragraph>100&nbsp;zł</Paragraph>
                  </td>
                  <td>
                    <Paragraph>1&nbsp;h</Paragraph>
                  </td>
                </tr>

                <tr>
                  <td>
                    <Paragraph>
                      Każde kolejne rozpoczęte 30 minut konsultacji
                    </Paragraph>
                  </td>
                  <td>
                    <Paragraph>30&nbsp;zł</Paragraph>
                  </td>
                  <td></td>
                </tr>

                <tr>
                  <td>
                    <Paragraph>
                      Terapia lęku separacyjnego (4&nbsp;tygodnie)
                    </Paragraph>
                  </td>
                  <td>
                    <Paragraph>500&nbsp;zł</Paragraph>
                  </td>
                  <td></td>
                </tr>
              </Table>
            </Description>
            <Description>
              <CostDriveInformation />
            </Description>
          </Container>

          <DownloadFormBanner dog={true} />
        </Wrapper>
      </Layout>
    </>
  )
}

export default DogBehavioralConsultationsPage
