import styled from 'styled-components'
import { Header1, Paragraph } from '@/theme/Typography.styled'
import { Container as _Container } from '@/theme/Grid.styled'
import { Color } from '@/theme/colors'
import { ButtonTextPink } from '@/theme/Button.styled'

export const Wrapper = styled.div`
  background-color: ${Color.Pink_1};
`

export const Container = styled(_Container)`
  position: relative;
  display: flex;
  justify-content: flex-end;
  height: 700px;
  padding-top: 100px;

  @media (max-width: 991px) {
    padding-top: 150px;
  }

  @media (max-width: 767px) {
    display: block;
    height: auto;
    padding-top: 0;
    padding-bottom: 40px;
  }
`

export const Image = styled.div`
  position: absolute;
  top: -1px;
  right: 46%;

  img {
    height: 647px;
  }

  @media (max-width: 767px) {
    position: relative;
    right: auto;

    img {
      width: 100%;
      height: auto;
    }
  }
`

export const Text = styled.div`
  width: 50%;
  text-align: center;

  @media (max-width: 991px) {
    width: 60%;
  }

  @media (max-width: 767px) {
    width: 100%;
  }
`

export const Header = styled(Header1)`
  text-align: center;
  margin-bottom: 15px;

  @media (max-width: 767px) {
    margin-bottom: 25px;
  }
`

export const Description = styled(Paragraph)`
  font-size: 26px;
  line-height: 36px;
  text-align: center;

  @media (max-width: 1199px) {
    font-size: 20px;
    line-height: 30px;
  }

  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 22px;
  }
`

export const Button = styled(ButtonTextPink)`
  font-size: 26px;
  line-height: 32px;
  text-decoration: none !important;

  @media (max-width: 1199px) {
    font-size: 20px;
    line-height: 30px;
  }

  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 22px;
  }
`
